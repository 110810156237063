import { datadogRum } from '@datadog/browser-rum';

export function initalizeMonitoring() {
  const interval = setInterval(function () {
    try {
      clearInterval(interval);
      datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID as string,
        clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
        site: 'datadoghq.eu',
        service: 'Umýsla',
        env: process.env.NEXT_PUBLIC_ENV ?? 'development',
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        trackFrustrations: true,
        version: '1.0.0',
        trackSessionAcrossSubdomains: true,
        defaultPrivacyLevel: 'mask',
        allowedTracingOrigins: [window.location.origin],
      });

      datadogRum.startSessionReplayRecording();
    } catch {}
  }, 200);
}
